import { Box, Flex, VStack } from '@chakra-ui/react'
import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { useSession } from 'next-auth/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { BsFillCaretDownFill } from 'react-icons/bs'
import {
  NAV_STRUCTURE,
  isNavLinkCollection,
  NavLinkCollection,
} from '../../constants/navigation'

const NavLinkContainer = styled(Flex)`
  height: 100%;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  gap: 6px;

  &:hover {
    background: ${({ theme }) => theme.colors.blue[600]};
  }
`

export const NavigationDesktop: React.FC = () => {
  const [selected, setSelected] = useState<NavLinkCollection | null>(null)
  const [mouseOverSubMenu, setMouseOverSubmenu] =
    useState<NavLinkCollection | null>(null)
  const theme = useTheme()
  const { pathname } = useRouter()
  const { status } = useSession()

  useEffect(() => {
    setSelected(null)
    setMouseOverSubmenu(null)
  }, [pathname, setSelected, setMouseOverSubmenu])

  return (
    <>
      {NAV_STRUCTURE.map((node, index) =>
        isNavLinkCollection(node) ? (
          <NavLinkContainer
            key={index}
            onMouseOver={() => setSelected(node)}
            onMouseLeave={() => setSelected(null)}
            onClick={() => setSelected(node)}
            position="relative"
            bg={
              selected?.title === node.title ||
              node.items.find((x) => pathname.startsWith(x.href))
                ? theme.colors.blue[600]
                : theme.colors.blue[500]
            }
          >
            {node.title} <BsFillCaretDownFill size={12} />
            <VStack
              display={
                selected?.title === node.title ||
                mouseOverSubMenu?.title === node.title
                  ? 'flex'
                  : 'none'
              }
              position="absolute"
              top="100%"
              alignItems="flex-start"
              background={theme.colors.white}
              color={theme.colors.black}
              left={0}
              textAlign="left"
              zIndex="1"
              spacing={0}
              whiteSpace="nowrap"
              onMouseOver={() => setMouseOverSubmenu(node)}
              onMouseLeave={() => setMouseOverSubmenu(null)}
              border={`1px solid ${theme.colors.gray[100]}`}
            >
              {node.items.map(({ href, title, target, page, redirect }) => {
                return (
                  <Link
                    key={href}
                    href={
                      redirect && status === 'authenticated' ? redirect : href
                    }
                    passHref
                    shallow
                    prefetch={false}
                    legacyBehavior
                  >
                    <Box
                      as={'a' as any}
                      px="15px"
                      py="10px"
                      w="100%"
                      target={target}
                      onClick={(e) => href === pathname && e.preventDefault()}
                      bg={
                        href === pathname || page === pathname
                          ? theme.colors.gray[100]
                          : theme.colors.white
                      }
                      css={css`
                        &:hover {
                          background: ${theme.colors.gray[100]};
                        }
                      `}
                    >
                      {title}
                    </Box>
                  </Link>
                )
              })}
            </VStack>
          </NavLinkContainer>
        ) : (
          <Link
            key={index}
            href={
              node.redirect && status === 'authenticated'
                ? node.redirect
                : node.href
            }
            passHref
            prefetch={false}
            legacyBehavior
          >
            <NavLinkContainer
              as="a"
              target={node.target}
              bg={
                pathname === node.href
                  ? theme.colors.blue[600]
                  : theme.colors.blue[500]
              }
            >
              {node.title}
            </NavLinkContainer>
          </Link>
        )
      )}
    </>
  )
}
