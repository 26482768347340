import {
  Button,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { BsList } from 'react-icons/bs'
import { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { css } from '@emotion/react'
import { NavigationDesktop } from './navigation-desktop'
import { NavigationMobile } from './navigation-mobile'
import { MobileBackButton } from './mobile-back-button'
import { LogoButton } from './logo-button'
import { useIsApp } from '../app-context/app-context'

export const Navbar: React.FC = () => {
  const { pathname } = useRouter()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const openDrawerButton = useRef(null)
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  })
  const isApp = useIsApp()

  useEffect(() => {
    onClose()
  }, [isMobile, onClose, pathname])

  return (
    <Flex
      bg="blue.500"
      color="white"
      justifyContent="space-between"
      pl="15px"
      zIndex={999}
    >
      {isApp ? <MobileBackButton /> : <LogoButton />}
      <Flex
        display={{
          base: 'none',
          md: 'flex',
        }}
      >
        <NavigationDesktop />
      </Flex>

      <Button
        aria-label="Menu"
        display={{
          base: 'flex',
          md: 'none',
        }}
        variant="unstyled"
        ref={openDrawerButton}
        colorScheme="teal"
        onClick={onOpen}
        h="100%"
        mr="10px"
        alignItems="center"
        css={css`
          &:hover {
            opacity: 0.7;
          }
        `}
      >
        <BsList size={32} fontWeight={500} />
      </Button>
      <Drawer
        isOpen={Boolean(isMobile && isOpen)}
        placement="right"
        onClose={onClose}
        finalFocusRef={openDrawerButton}
      >
        <DrawerOverlay />
        <DrawerContent>
          <NavigationMobile />
        </DrawerContent>
      </Drawer>
    </Flex>
  )
}
