export const styles = {
  global: {
    body: {
      minHeight: '100vh',
      display: 'flex',
      overflowX: 'hidden',
    },
    '#__next': {
      width: '100%',
    },
  },
}
