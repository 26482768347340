import { useRouter } from 'next/router'
import { createContext, useContext, useState, useEffect } from 'react'

type AppContextType = {
  isApp: boolean
}

const ctx = createContext<AppContextType>({} as AppContextType)

export const AppContextProvider: React.FC<{
  children: React.ReactElement
}> = ({ children }) => {
  const {
    query: { context },
  } = useRouter()

  const [isApp, setIsApp] = useState(false)

  useEffect(() => {
    if (isApp) {
      return
    }

    if (context === 'app') {
      setIsApp(true)
    }
  }, [context])

  return (
    <ctx.Provider
      value={{
        isApp,
      }}
    >
      {children}
    </ctx.Provider>
  )
}

export const useIsApp = () => {
  const context = useContext(ctx)
  return context.isApp
}
