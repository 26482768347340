import {
  DrawerCloseButton,
  Box,
  Flex,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/react'
import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { useSession } from 'next-auth/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { NAV_STRUCTURE, isNavLinkCollection } from '../../constants/navigation'

const NavLinkContainer = styled(Flex)`
  align-items: center;
  cursor: pointer;
  gap: 6px;
  border-color: ${({ theme }) => theme.colors.gray[200]};
  border-top-width: 1px;
  border-bottom-width: 1px;

  &:hover {
    background: ${({ theme }) => theme.colors.gray[200]};
  }
`

export const NavigationMobile: React.FC = () => {
  const theme = useTheme()
  const { pathname } = useRouter()
  const { status } = useSession()

  return (
    <>
      <DrawerCloseButton
        w="100%"
        position="static"
        textAlign="right"
        display="flex"
        justifyContent="flex-end"
        pr="15px"
        size="lg"
        css={css`
          &:hover {
            background: white;
          }

          & svg {
            opacity: 0.5;
          }

          &:hover svg {
            opacity: 1;
          }
        `}
      />
      <Accordion allowToggle>
        {NAV_STRUCTURE.map((node, index) =>
          isNavLinkCollection(node) ? (
            <AccordionItem key={index} p={0}>
              <AccordionButton
                p="16px"
                bg={
                  node.items.find((x) => x.href === pathname)
                    ? theme.colors.gray[100]
                    : theme.colors.white
                }
                css={css`
                  &:hover {
                    background: ${node.items.find((x) => x.href === pathname)
                      ? theme.colors.gray[100]
                      : theme.colors.gray[200]};
                  }
                `}
              >
                <Box flex="1" textAlign="left">
                  {node.title}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel
                p={0}
                display="flex"
                flexDirection="column"
                w="100%"
              >
                {node.items.map(({ href, title, target, redirect }) => (
                  <Link
                    key={href}
                    href={
                      redirect && status === 'authenticated' ? redirect : href
                    }
                    prefetch={false}
                    legacyBehavior
                  >
                    <NavLinkContainer
                      as="a"
                      key={index}
                      target={target}
                      onClick={(e) => href === pathname && e.preventDefault()}
                      p="16px"
                      pl="24px"
                      bg={
                        href === pathname
                          ? theme.colors.gray[200]
                          : theme.colors.gray[50]
                      }
                    >
                      {title}
                    </NavLinkContainer>
                  </Link>
                ))}
              </AccordionPanel>
            </AccordionItem>
          ) : (
            <Link
              key={index}
              href={
                node.redirect && status === 'authenticated'
                  ? node.redirect
                  : node.href
              }
              passHref
              prefetch={false}
              legacyBehavior
            >
              <NavLinkContainer
                as="a"
                key={index}
                p="16px"
                target={node.target}
                onClick={(e) => node.href === pathname && e.preventDefault()}
                bg={
                  node.href === pathname
                    ? theme.colors.gray[200]
                    : theme.colors.white
                }
              >
                {node.title}
              </NavLinkContainer>
            </Link>
          )
        )}
      </Accordion>
    </>
  )
}
