import { Flex, ChakraProvider } from '@chakra-ui/react'
import { Navbar } from '../components/navbar/navbar'
import { SessionProvider } from 'next-auth/react'
import { Footer } from '../components/footer/footer'
import { theme } from '../theme'
import { useRouter } from 'next/router'
import { DefaultSeo } from 'next-seo'
import { AppType } from 'next/dist/shared/lib/utils'
import { Session } from 'next-auth'
import { client } from '../api/trpc-client'
import { webConfig } from '../config/web-config'
import Script from 'next/script'
import { AppStoreBanner } from '../components/app-store-banner/app-store-banner'
import { AppContextProvider } from '../components/app-context/app-context'

import 'mapbox-gl/dist/mapbox-gl.css'

const App: AppType<{ session: Session }> = ({
  Component,
  pageProps: { session, ...pageProps },
}) => {
  const { pathname } = useRouter()
  const isSearchPage = pathname.startsWith('/listings/search')

  return (
    <>
      <DefaultSeo
        titleTemplate="%s | Northern Great Lakes REALTORS® MLS | REALTOR® Association Owned"
        defaultTitle="Northern Great Lakes REALTORS® MLS | REALTOR® Association Owned"
        description="The Northern Great Lakes REALTORS® MLS was created in 2012 as an effort to improve access to information for the public and REALTOR® members seeking to buy or sell real estate in northern Michigan."
        openGraph={{
          type: 'website',
          locale: 'en_US',
          url: 'https://nglrmls.com/',
          site_name: 'Northern Great Lakes REALTORS® MLS',
        }}
      />
      <SessionProvider session={session} basePath="/api/v2/auth">
        <AppContextProvider>
          <ChakraProvider theme={theme}>
            <AppStoreBanner />

            <Flex flexDirection="column" height="100%" as="main">
              <Flex
                flexDirection="column"
                height={isSearchPage ? '100vh' : '100%'}
              >
                <Navbar />
                <Component {...pageProps} />
              </Flex>
              {!isSearchPage && <Footer />}
            </Flex>
          </ChakraProvider>
        </AppContextProvider>
      </SessionProvider>
      {webConfig.analyticsId && (
        <>
          <Script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${webConfig.analyticsId}`}
          />
          <Script
            id="google-analytics"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${webConfig.analyticsId}');`,
            }}
          />
        </>
      )}
    </>
  )
}

export default client.withTRPC(App)
