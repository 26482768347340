import { RiGooglePlayFill, RiCloseFill } from 'react-icons/ri'
import { FaAppStore } from 'react-icons/fa'
import {
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  FlexProps,
} from '@chakra-ui/react'
import { Image } from '../image/image'
import { localStorageHelper } from '../../utils/local-storage/local-storage-helper'
import { webConfig } from '../../config/web-config'
import { useState, useEffect } from 'react'
import { useIsApp } from '../app-context/app-context'

type Props = FlexProps

export const AppStoreBanner: React.FC<Props> = () => {
  const [previouslyClosed, setPreviouslyClosed] = useState(true)
  const isApp = useIsApp()
  const [showBanner, setShowBanner] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShowBanner(true)
    }, 2000)
  }, [])

  useEffect(() => {
    if (isApp) {
      setPreviouslyClosed(true)
      return
    }

    const fromLocalStorage =
      localStorageHelper.get(webConfig.hideBannerKey) || null

    if (!fromLocalStorage) {
      setPreviouslyClosed(false)
      return
    }

    const previouslyClosedDate = new Date(fromLocalStorage)
    const now = new Date()

    // show banner if it's been more than 14 days since it was hidden
    if (
      now.getTime() - previouslyClosedDate.getTime() >
      1000 * 60 * 60 * 24 * 14
    ) {
      setPreviouslyClosed(false)
    }
  }, [])

  // to restore the banner, remove the negation
  if (!isApp) {
    return null
  }

  return (
    <Flex
      transition="all 1.2s"
      minH="0"
      maxHeight={showBanner ? '120px' : '0px'}
      pt={showBanner ? '15px' : '0px'}
      overflow="hidden"
      display={previouslyClosed ? 'none' : 'flex'}
      bg="white"
      h="120px"
      pos="fixed"
      zIndex={999}
      bottom={0}
      w="100%"
      justifyContent="center"
      gap={['8px', null, null, '30px']}
      overflowX="hidden"
      sx={{
        '& img': {
          // nextjs image doesn't allow changing these
          // which is weird because it's a div with a background image
          // but whatever
          width: 'auto !important',
          position: 'static !important',
        },
      }}
    >
      <IconButton
        onClick={() => {
          localStorageHelper.set(
            webConfig.hideBannerKey,
            new Date().toISOString()
          )
          setPreviouslyClosed(true)
        }}
        pos="absolute"
        right={[0, null, null, 16]}
        top={0}
        cursor="pointer"
        icon={<RiCloseFill />}
        aria-label="close"
        variant="ghost"
      />
      <Image
        src="/images/phone-mockup.png"
        alt="Mobile phone showing app"
        width={105}
        height={105}
      />
      <Box textAlign="center" mt={['8px', null, null, 0]}>
        <Text fontSize={['16px', null, null, '24px']}>
          Download the Mobile App
        </Text>
        <Flex
          gap={['8px', null, null, '16px']}
          justifyContent="center"
          mt="12px"
          minWidth={[null, null, null, '320px']}
        >
          <Button
            aria-label="App Store"
            as="a"
            href="https://apps.apple.com/us/app/nglr-mls/id1393854555?l=en"
            target="_blank"
            leftIcon={<FaAppStore />}
            variant="blue"
            fontSize={['12px', null, null, '20px']}
          >
            App Store
          </Button>
          <Button
            aria-label="Google Play"
            as="a"
            href="https://play.google.com/store/apps/details?id=com.realestate.nglr&hl=en_US"
            target="_blank"
            leftIcon={<RiGooglePlayFill />}
            variant="blue"
            fontSize={['12px', null, null, '20px']}
          >
            Google Play
          </Button>
        </Flex>
      </Box>
    </Flex>
  )
}
