import { ChakraTheme } from '@chakra-ui/react'

export const textStyles: ChakraTheme['textStyles'] = {
  mainTitle: {
    fontSize: '35px',
    fontWeight: 600,
    marginBottom: '35px',
    textShadow: '0 3px 3px rgb(0 0 0 / 80%)',
    color: 'white',
  },
  contentTitle: {
    fontSize: '40px',
    fontWeight: 500,
    marginBottom: '8px',
  },
}
