import NextImage, { ImageLoader } from 'next/image'
import {
  Box,
  BoxProps,
  Skeleton,
  Spinner,
  ThemingProps,
} from '@chakra-ui/react'
import { useState } from 'react'
import { thumborLoader } from './thumbor-loader'

const FAILED_IMAGE = '/images/house-placeholder.webp'

export const Image: React.FC<
  Omit<BoxProps, 'fill'> & {
    src: string
    alt: string
    useMap?: string
    priority?: boolean
    loader?: ImageLoader
    quality?: number
    sizes?: string
    width?: number
    height?: number
    fill?: boolean
    loadingIndicator?: boolean
    skeletonLoader?: boolean
    spinnerLoader?: boolean
    spinnerSize?: ThemingProps<'Spinner'>['size']
    spinnerThickness?: string
  } & {
    objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down'
    objectPosition?: string
  }
> = ({
  src,
  alt,
  objectFit,
  objectPosition,
  useMap,
  priority = false,
  quality = 75,
  sizes,
  width,
  height,
  fill,
  loadingIndicator = true,
  skeletonLoader = true,
  spinnerLoader = true,
  spinnerSize = 'xl',
  spinnerThickness = '4px',
  ...boxProps
}) => {
  const [imgSrc, setImgSrc] = useState(src)
  const [isLoaded, setIsLoaded] = useState(false)

  return (
    <Box
      backgroundColor={boxProps.bg || 'gray.50'}
      position="relative"
      overflow="hidden"
      {...boxProps}
    >
      {loadingIndicator && !isLoaded && (
        <Box pos="absolute" height="100%" width="100%" bg="gray.50">
          {skeletonLoader && (
            <Skeleton pos="absolute" bg="gray.50" width="100%" height="100%" />
          )}
          {spinnerLoader && (
            <Spinner
              pos="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              m="auto"
              thickness={spinnerThickness}
              speed="0.5s"
              emptyColor="gray.200"
              color="blue.500"
              size={spinnerSize}
            />
          )}
        </Box>
      )}
      <NextImage
        width={width}
        height={height}
        src={imgSrc}
        fill={fill}
        alt={alt}
        style={{
          objectFit: objectFit || 'cover',
          objectPosition: objectPosition || '	50% 50%',
        }}
        useMap={useMap}
        onError={() => {
          setImgSrc(FAILED_IMAGE)
        }}
        onLoad={() => setIsLoaded(true)}
        priority={priority}
        sizes={sizes}
        {...(imgSrc.startsWith('https://')
          ? { unoptimized: true }
          : { loader: thumborLoader, quality })}
      />
    </Box>
  )
}
