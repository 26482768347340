import { extendTheme } from '@chakra-ui/react'

import { colors } from './config/colors'
import { fonts } from './config/fonts'
import { styles } from './config/styles'
import { breakpoints } from './config/breakpoints'
import { components } from './config/components'
import { textStyles } from './config/text-styles'

const config = {
  colors,
  fonts,
  styles,
  breakpoints,
  components,
  textStyles,
}

export const theme = extendTheme(config)

export type CustomTheme = typeof config
