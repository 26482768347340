import { ThemeComponents } from '@chakra-ui/react'

export const components: ThemeComponents = {
  Container: {
    baseStyle: {
      maxWidth: '1440px',
      px: {
        base: '16px',
        xs: '16px',
        md: '64px',
      },
    },
  },
  Button: {
    baseStyle: {
      _focus: {
        boxShadow: 'none',
      },
    },
    variants: {
      blue: {
        background: 'blue.500',
        color: 'white',
        _hover: {
          background: 'blue.600',
          _disabled: {
            background: 'blue.200',
          },
        },
        _active: {
          background: 'blue.700',
        },
      },
      white: {
        background: 'white',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'gray.200',
        _hover: {
          bg: 'gray.50',
        },
        _active: {
          bg: 'gray.50',
        },
      },
    },
  },
  Divider: {
    baseStyle: {
      my: '8px',
      mb: '32px',
    },
  },
  Text: {
    variants: {
      paragraph: {
        marginBottom: '16px',
      },
      link: {
        cursor: 'pointer',
        color: 'blue.500',
        _hover: {
          color: 'blue.500',
          textDecoration: 'underline',
        },
      },
      listingTitle: {
        fontSize: '32px',
        mb: '8px',
      },
      listingSubTitle: {
        fontSize: '28px',
        mb: '8px',
      },
      memberH1: {
        fontSize: '40px',
        pb: '8px',
      },
      memberH2: {
        color: 'blue.500',
        fontSize: '32px',
        pb: '8px',
      },
      memberH3: {
        color: 'black',
        fontSize: '24px',
        pb: '8px',
      },
    },
  },
}
