import { Flex, Text } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'

export const MobileBackButton: React.FC = () => {
  const { back } = useRouter()

  return (
    <Flex onClick={back} as="button" py="10px" gap="0px" alignItems="center">
      <MdOutlineArrowBackIosNew size="1.5em" style={{ marginRight: 0 }} />
      <Text fontSize="22px" ml="10px" pos="relative" bottom="1px">
        Back
      </Text>
    </Flex>
  )
}
