import { Box, Container, Grid, Text, VStack } from '@chakra-ui/react'
import Link from 'next/link'
import { FOOTER_STRUCTURE } from '../../constants/navigation'
import { css } from '@emotion/react'
import { Image } from '../image/image'

const LOGO_MOBILE_SIZE = '60px'
const LOGO_DESKTOP_SIZE = '60px'

export const Footer: React.FC = () => {
  return (
    <Box bg="blue.500" color="white" as="nav">
      <Container
        display="flex"
        flexDirection={{
          base: 'column',
          sm: 'row',
        }}
        gap={{
          base: '48px',
          sm: '64px',
        }}
        justifyContent="center"
        py="48px"
      >
        <Box
          position="relative"
          display={{
            base: 'none',
            sm: 'block',
          }}
          w={{
            base: LOGO_MOBILE_SIZE,
            md: LOGO_DESKTOP_SIZE,
          }}
          h={{
            base: LOGO_MOBILE_SIZE,
            md: LOGO_DESKTOP_SIZE,
          }}
        >
          <Image
            width={60}
            height={60}
            src="/images/nav-logo.png"
            alt="logo"
            bg="none"
            loadingIndicator={false}
          />
        </Box>
        <Grid
          gridTemplateColumns={{
            base: 'auto',
            sm: 'auto auto',
            md: 'auto auto auto auto',
          }}
          columnGap={{
            base: '48px',
            sm: '64px',
          }}
          rowGap="48px"
        >
          {FOOTER_STRUCTURE.map(({ items, title }) => (
            <Box key={title}>
              <Text as="h2" fontSize="20px" mb="12px">
                {title}
              </Text>
              <VStack alignItems="flex-start">
                {items.map(({ href, title, target }) => (
                  <Link href={href} key={href} passHref legacyBehavior>
                    <Text
                      target={target}
                      as="a"
                      css={css`
                        &:hover {
                          text-decoration: underline;
                        }
                      `}
                    >
                      {title}
                    </Text>
                  </Link>
                ))}
              </VStack>
            </Box>
          ))}
        </Grid>
      </Container>
      <Box bg="blue.700" p="24px" textAlign="center" color="blue.100">
        © Northern Great Lakes REALTORS MLS™ {new Date().getFullYear()}
      </Box>
    </Box>
  )
}
