export const colors = {
  gray: {
    50: '#F7FAFC',
    100: '#EDF2F7',
    200: '#E2E8F0',
    300: '#CBD5E0',
    400: '#A0AEC0',
    500: '#718096',
    600: '#4A5568',
    700: '#2D3748',
    800: '#1A202C',
    900: '#171923',
    1000: '#003e81',
  },
  blue: {
    50: '#ebf2f9',
    100: '#cee1f5',
    200: '#a9c9ed',
    300: '#84b1e5',
    400: '#5e99de',
    500: '#0072c6',
    600: '#0063ac',
    700: '#005590',
    800: '#004775',
    900: '#003859',
  },
  white: '#FFFFFF',
  black: '#000000',
}
