import { Box, Flex, Text } from '@chakra-ui/react'
import Link from 'next/link'
import { Image } from '../image/image'

const LOGO_MOBILE_SIZE = '41px'
const LOGO_DESKTOP_SIZE = '60px'

export const LogoButton: React.FC = () => {
  return (
    <Link href="/" passHref shallow prefetch={false} legacyBehavior>
      <Flex as="a" py="10px" gap="15px">
        <Box
          position="relative"
          w={{
            base: LOGO_MOBILE_SIZE,
            md: LOGO_DESKTOP_SIZE,
          }}
          h={{
            base: LOGO_MOBILE_SIZE,
            md: LOGO_DESKTOP_SIZE,
          }}
        >
          <Image
            width={60}
            height={60}
            src="/images/nav-logo.png"
            alt="logo"
            bg="none"
            priority
            loadingIndicator={false}
          />
        </Box>
        <Flex flexDirection="column" justifyContent="center">
          <Text
            fontWeight={400}
            lineHeight={1.5}
            fontSize={{
              base: '14px',
              sm: '20px',
            }}
          >
            Northern Great Lakes REALTORS MLS™
          </Text>
          <Text fontSize="14px">REALTOR® Association Owned</Text>
        </Flex>
      </Flex>
    </Link>
  )
}
